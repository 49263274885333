import './App.css';
import Time from "./Time.js";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div class="font-body text-6xl">
          <p class="mt-6 text-lg leading-8 text-gray-300">React & Tailwind</p>
          <Time />
        </div>
      </header>
    </div>
  );
}


export default App;
